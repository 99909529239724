import { io, Socket } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { BankService } from './bank.service';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    private socket:Socket = undefined; 
    
    public isReady = false;

    constructor(private authService:AuthService, private bankService:BankService) {
        this.socket = io(environment.socketUrl);

        this.addListener('login-ok', () => {
            console.log('Websocket: login succesfull');
            this.isReady = true;
        }); 
    
        this.addListener('error', (data) => {
          console.log('Websocket: Error');
          console.log(data);
        });

        this.addListener("connect", () => {
          console.log("Websocket: connection started")
          if(this.authService.currentUserValue) {
            this.connection(this.authService.currentUserValue.id)
          }
        });

        this.addListener("disconnect", () => {
          console.log("Websocket: disconnection")
          this.isReady = false;
        });
    }

    addListener(type, handler) {
      console.log('Add listener ' + type + ' on websocket');
      this.socket.on(type, handler);
    }

    emit(type, args=undefined) {
      console.log('Emit ' + type + ' on websocket');
      if(args === undefined) {
        this.socket.emit(type);
      } else {
        this.socket.emit(type, args);
      }
    }

    callrequestAccess(storeToken, room, door, userId, paymentIntentId = undefined) {
      let params = {
        'object-token': storeToken,
        'room': room,
        'door': door,
        'userId': userId
      };
      if(paymentIntentId !== undefined) {
        params['paymentIntentId'] = paymentIntentId;
      }
      this.emit('request-access', params);
    }
    
    connection(userId) {
      console.log("Websocket, login with id: " + userId)
      this.emit('login', { 'id': userId, 'type': 'user' });
    }

    getSocket() {
      return this.socket;
    }

    disconnection() {
        console.log("Websocket: force disconnection")
        this.socket.disconnect();
    }
}